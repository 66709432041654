import { object, mixed, boolean, string, AnyObjectSchema } from 'yup'
import { TFunction } from 'i18next'
import {
  CaseArcadeType,
  CasePrintingScannerType,
  CasePrintingType,
  CaseType,
  isSecretRetainer,
} from '@app/services/cases/types'
import { STLIdentifiers } from '@app/services/files/types'
import { getFormValuesFromContext } from '@app/core/validations/utils'

function measuresCommonValidationSchema(t: TFunction): AnyObjectSchema {
  return object().shape({
    accept: boolean().required(t('validation.required')),
    published: boolean().required(t('validation.required')),
  })
}

export function measuresValidationSchema(
  t: TFunction,
  caseType: CaseType,
): AnyObjectSchema {
  const casePrintingType = Object.values(CasePrintingType) as CasePrintingType[]

  if (isSecretRetainer(caseType)) {
    return measuresCommonValidationSchema(t)
  }

  return object()
    .shape({
      measurement: object().shape({
        method: mixed<CasePrintingType>()
          .oneOf(casePrintingType)
          .required(t('validation.required')),
        scanner: mixed<CasePrintingScannerType>()
          .when('method', {
            is: CasePrintingType.STLInScannerPlatform,
            then: (schema) => schema
              .required(t('validation.required'))
              .oneOf(Object.values(CasePrintingScannerType)),
          }),
        files: object().shape({
          [STLIdentifiers.Upper]: object().shape({
            file: string().test(
              'fileArcadeType',
              () => t('validation.required'),
              (value, context) => {
                const {
                  measurement: { method },
                } = getFormValuesFromContext<CaseArcadeType>(context)
                if (value) {
                  return true
                }

                if (method && method !== CasePrintingType.STLAttached) {
                  return true
                }

                if (!value) {
                  return false
                }

                return true
              },
            ),
          }),
          [STLIdentifiers.Lower]: object().shape({
            file: string().test(
              'fileArcadeType',
              () => t('validation.required'),
              (value, context) => {
                const {
                  measurement: { method },
                } = getFormValuesFromContext<CaseArcadeType>(context)
                if (value) {
                  return true
                }

                if (method && method !== CasePrintingType.STLAttached) {
                  return true
                }

                if (!value) {
                  return false
                }

                return true
              },
            ),
          }),
        }),
      }),
    })
    .concat(measuresCommonValidationSchema(t))
}
