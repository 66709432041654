import { Main, StateHandler } from '@app/components'
import { useMemo } from 'react'
import { useBillingAddress, useCaseDetail, useClinics } from '@app/hooks'
import { CaseEditForm } from './form/CaseEditForm'
import { useParams } from 'react-router-dom'

import { usePrescription } from '@app/hooks/prescriptions/usePrescriptions'

export const CaseEditPage = () => {
  const { caseId } = useParams()
  const {
    state: { loaded: loadedCase, error: errorCase, case: caseDetail },
  } = useCaseDetail(caseId!)
  const {
    state: { loaded: loadedClinics, error: errorClinics, clinics },
  } = useClinics()
  const {
    state: {
      loaded: loadedBillingAddress,
      error: errorBillingAddress,
      billingAddressList,
    },
  } = useBillingAddress()

  const {
    problemsState: { loaded: loadedProblems, error: errorProblems, problems },
    solutionsState: {
      loaded: loadedSolutions,
      error: errorSolutions,
      solutions,
    },
  } = usePrescription(caseDetail?.type)

  const loadedPrescriptions = useMemo(
    () => loadedProblems && loadedSolutions,
    [loadedProblems, loadedSolutions],
  )

  const errorPrescriptions = useMemo(
    () => errorProblems || errorSolutions,
    [errorProblems, errorSolutions],
  )

  const loadedData = useMemo(
    () =>
      loadedClinics &&
      loadedBillingAddress &&
      loadedCase &&
      loadedPrescriptions,
    [loadedClinics, loadedBillingAddress, loadedCase, loadedPrescriptions],
  )

  const errorData = useMemo(
    () =>
      errorClinics || errorBillingAddress || errorCase || errorPrescriptions,
    [errorClinics, errorBillingAddress, errorCase, errorPrescriptions],
  )

  return (
    <Main ariaLabelledby="case-new-page" className="CaseNewPage">
      <StateHandler loaded={loadedData} error={errorData}>
        <CaseEditForm
          caseData={caseDetail!}
          clinics={clinics || []}
          problems={problems!}
          solutions={solutions!}
          billingAddress={billingAddressList || []}
        />
      </StateHandler>
    </Main>
  )
}
