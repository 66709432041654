import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import { mapEnumIntoIterableTypeValue } from '@app/utils'
import { CaseType, CaseTypeOption } from '@app/services/cases/types'
import { Card } from '@app/components'
import { SecretCaseTypeRadioControl } from './secret-radio-control/SecretCaseTypeRadioControl'
import { useFormContext } from 'react-hook-form'
import { FormControlFeedback } from '@app/components/forms/form-control-wrapper/FormControlWrapper'
import { CaseFormFields } from '../types'

import './TreatmentTypeForm.scss'

const dataTypeOptions = mapEnumIntoIterableTypeValue(
  CaseType,
  'cases.case-type',
)

type TreatmentTypeFormProps = {
  secretCases: CaseTypeOption[]
}

export const TreatmentTypeForm: FC<TreatmentTypeFormProps> = ({
  secretCases,
}) => {
  const { t } = useTranslation()

  const {
    formState: { errors },
  } = useFormContext<CaseFormFields>()

  const typeErrorsWatch = useMemo(() => errors.type, [errors.type])

  const combinedSecretCases = secretCases.map(secretCase => {
    const dataOption = dataTypeOptions.find(
      option => option.value === secretCase.id,
    )

    const refinementsTranslation = t(`${dataOption?.type}.refinements`, {
      defaultValue: '',
    })

    return {
      ...secretCase,
      ...dataOption,
      refinements: refinementsTranslation.trim()
        ? refinementsTranslation
        : null,
    }
  })

  return (
    <>
      <Card.Body size={'xl'} className="SecretCaseType">
        <div className="input-group">
          <SecretCaseTypeRadioControl
            required
            options={combinedSecretCases.map(d => ({
              label: t(`${d.type}.label`),
              body: t(`${d.type}.body`),
              value: d.id,
              name: t(`${d.type}.name`),
              stages: t(`${d.type}.stages`),
              refinements: d.refinements,
              freeStudy:t(`${d.type}.free-study`),
              warranty: t(`${d.type}.warranty`),
              freeShipping: t(`${d.type}.free_shipping`),
              cancellation: t(`${d.type}.cancellation`),
              priceOneArch: d.priceOneArch,
              priceBothArch: d.priceBothArch,
              isActive: d.isActive,
            }))}
          />

          {typeErrorsWatch && (
            <FormControlFeedback
              level={'error'}
              message={t('cases.errors.type')}
            />
          )}
        </div>
      </Card.Body>
    </>
  )
}
