import { useAuth } from '@app/hooks'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const PublicRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth()
  if (!isAuthenticated()) {
    return <Outlet />
  } else {
    return <Navigate to="/dashboard" />
  }
}

export default PublicRoutes
