import {
  CaseArcadeType,
  CaseCutType,
  CasePrintingType,
  CaseSecretRetainerType,
  CaseTargetType,
  CasesTeethNotMove,
  GenderType,
  CaseType,
  CasePrintingScannerType,
} from '@app/services/cases/types'
import {
  CaseFileType,
  PhotoIdentifiers,
  RadioIdentifiers,
  CaseFile,
} from '@app/services/files/types'

export enum CaseStep {
  Type = 0,
  General = 1,
  Specific = 2,
  Files = 3,
  Radiographs = 4,
  Measures = 5,
}

export type PatientProblemForm = {
  problemId: string
  value: boolean
  solutionId?: string
  otherSolution?: string
}

export type StepForm = {
  currentStep?: CaseStep
}

export type FileFormFields = Partial<{
  identifier: CaseFileType
  originalName: string
  file: string
}>[]

export type FilesPhotoFormFields = {
  [PhotoIdentifiers.PhotographsFrontal]: FileFormFields
  [PhotoIdentifiers.PhotographsRetractors]: FileFormFields
  [PhotoIdentifiers.PhotographsProfile]: FileFormFields
  [PhotoIdentifiers.UpperOcclusal]: FileFormFields
  [PhotoIdentifiers.LowerOcclusal]: FileFormFields
  [PhotoIdentifiers.FrontalOcclusal]: FileFormFields
  [PhotoIdentifiers.LeftOcclusal]: FileFormFields
  [PhotoIdentifiers.RightOcclusal]: FileFormFields
}

export type FilesRadioFormFields = {
  [RadioIdentifiers.RadioOrth]: FileFormFields
  [RadioIdentifiers.RadioTel]: FileFormFields
  [RadioIdentifiers.CBCT]: FileFormFields
}

export type FilesStlsFormFields = {
  [STLIdentifiers.Upper]: FileFormFields
  [STLIdentifiers.Lower]: FileFormFields
  [STLIdentifiers.Occlusal]: FileFormFields
}

export type FilesFormFields = {
  files: {
    [key in CaseFileType]?: CaseFile[]
  }
}

export enum STLIdentifiers {
  Upper = 'intraoral-scannings-upper',
  Lower = 'intraoral-scannings-lower',
  Occlusal = 'intraoral-scannings-occlusal',
}

export type MeasurementFiles = {
  'intraoral-scannings-upper': {
    file: boolean
  }
  'intraoral-scannings-lower': {
    file: boolean
  }
  'intraoral-scannings-occlusal': {
    file: boolean
  }
}

export type MeasuresFields = {
  measurement: {
    method?: CasePrintingType
    scanner?: CasePrintingScannerType
    files: MeasurementFiles
  }
  accept: boolean
  published: boolean
}

export type SpecificInfoFields = {
  specific: {
    cutType: CaseCutType
    secretRetainers: CaseSecretRetainerType
    useElastics?: boolean
    distalize?: boolean
    thirdMolarExtraction?: boolean
    target: CaseTargetType
    patientProblems: PatientProblemForm[]
    indications: string
  }
}

export type CaseCreateFormFields = StepForm & {
  type: CaseType | undefined
  general: {
    firstName: string
    lastName: string
    gender: GenderType | undefined
    birthdate: string
    archTreat: CaseArcadeType | undefined
    teethNotToMove: CasesTeethNotMove[]
    comments: string
    clinicId: string
    billingAddressId: string
  }
}

export type CaseEditFormFields = StepForm &
  CaseCreateFormFields &
  SpecificInfoFields &
  FilesFormFields &
  MeasuresFields

export type CaseFormFields = CaseEditFormFields
