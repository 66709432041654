import React, { FC, PropsWithChildren } from 'react'
import { CardContainerType } from './types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import './Card.scss'

type BodyContainerSize = 'sm' | 'default' | 'xl'

const CardContainer: FC<CardContainerType> = ({ children, to, className }) => {
  const card = <article className={clsx('card', className)}>{children}</article>
  if (to) {
    return (
      <Link className="Card-link" to={to}>
        {card}
      </Link>
    )
  }
  return card
}

const CardHeader: FC<PropsWithChildren> = ({ children }) => {
  return <div className="card-header">{children}</div>
}

const CardBody: FC<
  PropsWithChildren & { className?: string; size?: BodyContainerSize }
> = ({ children, className, size = 'default' }) => {
  return (
    <div className={clsx('card-body', className, `Card-${size}`)}>
      {children}
    </div>
  )
}

const CardFooter: FC<
  PropsWithChildren & { className?: string; size?: BodyContainerSize }
> = ({ children, className, size }) => {
  const hasSingleButton =
    React.Children.count(children) === 1 && React.isValidElement(children)
  return (
    <div
      className={clsx('card-footer Card-footer', className, size, {
        onlyOne: hasSingleButton,
      })}
    >
      {children}
    </div>
  )
}

const CardTitle: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <h2 className={clsx('card-title h5 text-dark Card-title', className)}>
      {children}
    </h2>
  )
}

const CardBadge: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  return <span className={clsx('Card-badge', className)}>{children}</span>
}

interface CardComponent extends FC<CardContainerType> {
  Header: FC<PropsWithChildren>
  Body: FC<PropsWithChildren & { className?: string; size?: BodyContainerSize }>
  Footer: FC<
    PropsWithChildren & { className?: string; size?: BodyContainerSize }
  >
  Title: FC<PropsWithChildren & { className?: string }>
  Badge: FC<PropsWithChildren & { className?: string }>
}

export const Card: CardComponent = Object.assign(CardContainer, {
  Header: CardHeader,
  Body: CardBody,
  Footer: CardFooter,
  Title: CardTitle,
  Badge: CardBadge,
})
