import Navbar from 'react-bootstrap/esm/Navbar'
import { Link, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Offcanvas from 'react-bootstrap/esm/Offcanvas'
import Nav from 'react-bootstrap/esm/Nav'
import {
  OptionNav,
  menuRoutes,
  newPatientOption,
} from '@app/routes/routes-list'
import { FC, useState } from 'react'
import { ButtonPhone, SidebarNav } from '@app/components'
import { CiCalendar, CiYoutube } from 'react-icons/ci'

import './NavbarMenu.scss'
import { useAppSelector } from '@app/store/hooks'

export const NavbarMenu: FC = () => {
  const { t } = useTranslation()
  const { token } = useAppSelector(state => state.user)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div className="NavbarMenu-container">
      <Navbar className="NavbarMenu" expand={'xl'} data-bs-theme="dark">
        <Navbar.Toggle
          className="NavbarMenu-toggle"
          aria-controls={`offcanvas-expand-lg`}
          onClick={handleShow}
        />

        <Navbar.Offcanvas
          show={show}
          onHide={handleClose}
          data-bs-theme="dark"
          id={`offcanvas-expand-lg`}
          aria-labelledby={`offcanvasLabel-expand-lg`}
          placement="start"
          className="NavbarMenu-offcanvas"
        >
          <div className="NavbarMenu-wrapper">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body className="NavbarMenu-body">
              <SidebarNav className="NavbarMenu-nav">
                <Nav.Link
                  as={Link}
                  to={newPatientOption.to}
                  eventKey={newPatientOption.to}
                  onClick={handleClose}
                >
                  {newPatientOption.icon} {t(`menu.${newPatientOption.title}`)}
                </Nav.Link>

                {menuRoutes.map(option => (
                  <NavLink
                    key={option.to}
                    item={option}
                    onClick={handleClose}
                  />
                ))}
                <SidebarNav.Item>
                  <ExternalNavLink
                    icon={<CiYoutube />}
                    title={'secret'}
                    to={
                      import.meta.env.VITE_ACADEMY_URL +
                      '?mo_jwt_token=' +
                      token
                    }
                    onClick={handleClose}
                  />
                </SidebarNav.Item>
                <SidebarNav.Item>
                  <ExternalNavLink
                    icon={<CiCalendar />}
                    title={'curses'}
                    to="https://www.proclinic.es/tienda/eventos?_gl=1*4q81ve*_ga*MTY2MzU0ODA3My4xNjcwODYwMjgy*_ga_23NQXNKHHZ*MTY3OTU5NzMzMy40OC4xLjE2Nzk1OTczNDcuNDYuMC4w"
                    onClick={handleClose}
                  />
                </SidebarNav.Item>
              </SidebarNav>

              <div className="d-flex d-lg-none NavbarMenu-phone">
                <ButtonPhone />
              </div>
            </Offcanvas.Body>
          </div>
        </Navbar.Offcanvas>
      </Navbar>
    </div>
  )
}

interface NavLinkProps {
  item: OptionNav
  onClick: () => void
}

const NavLink: FC<NavLinkProps> = ({ item, onClick }) => {
  const { t } = useTranslation()
  const active = !!useMatch({ path: item.to, end: false })

  return (
    <Nav.Item>
      <Nav.Link
        as={Link}
        to={item.to}
        eventKey={item.to}
        active={active}
        onClick={onClick}
      >
        {item.icon} {t(`menu.${item.title}`)}
      </Nav.Link>
    </Nav.Item>
  )
}

interface ExternalNavLinkProps {
  to: string
  icon: JSX.Element
  title: string
  onClick: () => void
}

const ExternalNavLink: FC<ExternalNavLinkProps> = ({
  icon,
  title,
  to,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <Nav.Link
      as={Link}
      rel="noopener noreferrer"
      to={to}
      target="_blank"
      onClick={onClick}
    >
      {icon} {t(`menu.${title}`)}
    </Nav.Link>
  )
}
