import { useAuth } from '@app/hooks'
import { MainLayout } from '@app/layouts'
import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export const PrivateRoutes: React.FC = () => {
  const location = useLocation()
  const { isAuthenticated, checkSession, changeTokenByUrl } = useAuth()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('_authToken')

    if (token) {
      changeTokenByUrl(token)
    }
  }, [location, changeTokenByUrl, checkSession])

  if (isAuthenticated()) {
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    )
  } else {
    return <Navigate to="/login" />
  }
}

export default PrivateRoutes
