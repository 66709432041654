import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { CaseType, CaseTypeOption } from '../cases/types'
import { ResponseData } from '@app/core/types'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'
import { TreatmentTypeResponse } from './types'

const getBaseUrl = (config: IConfigService) =>
  `${config.baseUrl}/treatment-types`

const getSecretCases =
  (config: IConfigService, http: IHttpClient) =>
  async (): Promise<CaseTypeOption[]> => {
    try {
      const { data } = await http.get<ResponseData<TreatmentTypeResponse[]>>(
        `${getBaseUrl(config)}`,
      )

      const mapped = data
        .filter(item =>
          Object.values(CaseType).includes(item.treatmentType as CaseType),
        )
        .map(item => {
          return {
            id: item.treatmentType,
            secretType: item.treatmentType as CaseType,
            priceOneArch: item.pricing?.single?.formatted ?? '',
            priceBothArch: item.pricing?.both?.formatted ?? '',
            isActive: item.available,
          }
        })

      const order: CaseType[] = [
        CaseType.Secret10,
        CaseType.Secret20,
        CaseType.SecretFull,
        CaseType.SecretTeen,
        CaseType.SecretRetainer3x,
      ]

      return mapped.sort(
        (a, b) => order.indexOf(a.secretType) - order.indexOf(b.secretType),
      )
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getSecretCases: getSecretCases(config, http),
})

export default factory(config, http)
