import { AxiosError } from 'axios'

import {
  ApiBadRequestError,
  ApiConflictError,
  ApiNotFoundError,
  isAxiosError,
  IApiError,
  ApiError,
  ApiForbiddenError,
  ApiUnprocessableEntityError,
} from '..'
import { HttpStatusCode } from '../status-codes'

export function errorFactory<T extends ApiError[]>(
  error: AxiosError<T> | unknown,
): IApiError {
  if (!isAxiosError(error)) {
    throw error
  }

  switch (error.response!.status) {
    case HttpStatusCode.BAD_REQUEST:
      return new ApiBadRequestError((error as AxiosError<T>).response!.data)
    case HttpStatusCode.NOT_FOUND:
      return new ApiNotFoundError((error as AxiosError<T>).response!.data)
    case HttpStatusCode.CONFLICT:
      return new ApiConflictError((error as AxiosError<T>).response!.data)
    case HttpStatusCode.FORBIDDEN:
      return new ApiForbiddenError((error as AxiosError<T>).response!.data)
    case HttpStatusCode.UNPROCESSABLE_ENTITY:
      return new ApiUnprocessableEntityError(
        (error as AxiosError<T>).response!.data,
      )
    default:
      throw new Error('API responded with an unknown error')
  }
}
