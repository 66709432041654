import { Card } from '@app/components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CaseFormFields } from '../types'
import { CaseFile, PhotoIdentifiers } from '@app/services/files/types'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHandleUpload } from '@app/hooks/files/useHandleUpload'
import { CaseMediaPreview } from '@app/components/media-preview/CaseMediaPreview'
import filesService from '@app/services/files/files.service'
import { groupBy } from '@app/utils/groupby'
import { useHandleDelete } from '@hooks/files/useHandleDelete.ts'

import './FilesForm.scss'

export const FilesForm: FC = () => {
  const { caseId } = useParams()
  const { t } = useTranslation()
  const { getValues } = useFormContext<CaseFormFields>()
  const { handleUpload } = useHandleUpload(caseId!)
  const { handleDelete } = useHandleDelete(caseId!)

  const [files, setFiles] = useState(getValues('files'))
  const [isUploadingStates, setIsUploadingStates] = useState<{
    [key: string]: boolean
  }>({})

  const onFileChange =
    (formName: keyof CaseFormFields) => async (files?: File[]) => {
      const identifier = formName.split('.')[2] as string
      setIsUploadingStates(prev => ({ ...prev, [identifier]: true }))
      await handleUpload(formName, files)
      setFiles(groupBy(await filesService.getCaseFiles(caseId!), 'identifier'))
      setIsUploadingStates(prev => ({ ...prev, [identifier]: false }))
    }

  const onFileDelete = async (file: CaseFile) => {
    const identifier = file.identifier
    setIsUploadingStates(prev => ({ ...prev, [identifier]: true }))
    await handleDelete([file])
    setFiles(groupBy(await filesService.getCaseFiles(caseId!), 'identifier'))
    setIsUploadingStates(prev => ({ ...prev, [identifier]: false }))
  }

  return (
    <Card.Body className="FilesForm" size={'sm'}>
      <h3 className="form-subtitle">{t('cases.multimedia.photos')}</h3>
      <div className="FilesForm-list">
        {Object.values(PhotoIdentifiers).map(identifier => (
          <div className="FilesForm-item" key={identifier}>
            <CaseMediaPreview
              key={identifier}
              name={
                `files.photographs.${identifier}.file` as keyof CaseFormFields
              }
              label={t(`cases.multimedia.${identifier}`)}
              onChange={onFileChange(
                `files.photographs.${identifier}.file` as keyof CaseFormFields,
              )}
              identifier={identifier}
              files={files?.[identifier] ?? []}
              accept=".png,.pneg,.jpg,.jpeg,.tiff,.heic,capture=camera"
              handleDelete={onFileDelete}
              isUploading={isUploadingStates[identifier]}
            />
          </div>
        ))}
      </div>
    </Card.Body>
  )
}
