import moment from 'moment'

export function formatDate(value: string | Date | undefined): string {
  if (!value) {
    return ''
  }

  return moment(value).format('DD/MM/YYYY')
}

export function formatTime(value: string | Date): string {
  if (!value) {
    return ''
  }
  const newDate = new Date(value)
  const timePart = newDate.toLocaleTimeString('es-ES', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
  return timePart
}

export function generateIntervalHours(startHour: string, endHour: string) {
  const intervalHours = []
  const startTime = new Date('January 1, 2024 ' + startHour)
  const endTime = new Date('January 1, 2024 ' + endHour)

  // eslint-disable-next-line no-unmodified-loop-condition
  while (startTime <= endTime) {
    intervalHours.push(
      startTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
    )
    startTime.setMinutes(startTime.getMinutes() + 30)
  }

  return intervalHours
}

export function validateTimeInterval(
  startIntervalTime?: string,
  endIntervalTime?: string,
): boolean {
  if (!startIntervalTime && !endIntervalTime) {
    return true
  }

  if (
    (!startIntervalTime && endIntervalTime) ||
    (startIntervalTime && !endIntervalTime)
  ) {
    return false
  }

  return startIntervalTime! < endIntervalTime!
}

export function validateFromToDate(from?: string, to?: string): boolean {
  if (!from || !to) {
    return true
  }

  return new Date(from!) <= new Date(to!)
}
