import { PropsWithChildren } from 'react'
import { Sidebar } from './sidebar/Sidebar'

import './MainLayout.scss'

export const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="MainLayout">
      <div className="MainLayout-container">
        <Sidebar />

        <div className="MainLayout-main">
          <div className="container-xxxl">{children}</div>
        </div>
      </div>
    </div>
  )
}
