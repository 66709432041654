import { Dispatch, SetStateAction, useMemo } from 'react'
import { allowedStatusToEdit } from '../../utils'
import {
  Case,
  CaseStatus,
  isSecretRetainer,
  UpdateCaseRequest,
} from '@app/services/cases/types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@app/hooks'
import {
  getNextStep,
  getPrevStep,
  mapCaseEditFormValuesForRequest,
} from './utils'
import { CaseEditFormFields, CaseStep } from '../../shared/form-steps/types'
import casesService from '@app/services/cases/cases.service'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { getStepKey } from '../../shared/form-steps/utils'
import { FormStep } from '../../shared/form/case-stepper/CaseStepper'

export const DEFAULT_EDIT_STEP = CaseStep.Specific
type UseCaseEditFormType = {
  handleNext: () => Promise<void>
  handlePublishPatient: SubmitHandler<CaseEditFormFields>
  handleBack: () => Promise<void>
  handleSaveAsDraft: () => Promise<void>
  allowEdit: boolean
  formSteps: FormStep[]
  lastStep: CaseStep
  canPublish: boolean
  isPublished: boolean
  isSecretRetainerType: boolean
}

type UseCaseEditParams = {
  caseData: Case
  methods: UseFormReturn<CaseEditFormFields, undefined, undefined>
  setActiveStep: Dispatch<SetStateAction<CaseStep>>
  activeStep: CaseStep
}

export const useCaseEditForm = ({
  caseData,
  methods,
  setActiveStep,
  activeStep,
}: UseCaseEditParams): UseCaseEditFormType => {
  const { t } = useTranslation()
  const { toastError, toastSuccess } = useToast()
  const navigate = useNavigate()

  const { trigger, getValues, clearErrors } = methods

  const allowEdit = useMemo(
    () => allowedStatusToEdit.some(arr => arr === caseData?.status),
    [caseData?.status],
  )

  const isSecretRetainerType = useMemo(
    () => isSecretRetainer(caseData.type),
    [caseData.type],
  )

  const formSteps = useMemo(() => {
    const steps = [
      {
        id: CaseStep.General,
        label: getStepKey(CaseStep.General),
      },
    ]

    if (!isSecretRetainerType) {
      steps.push(
        {
          id: CaseStep.Specific,
          label: getStepKey(CaseStep.Specific),
        },
        {
          id: CaseStep.Files,
          label: getStepKey(CaseStep.Files),
        },
        {
          id: CaseStep.Radiographs,
          label: getStepKey(CaseStep.Radiographs),
        },
      )
    }

    if (caseData.status === CaseStatus.Creating) {
      steps.push({
        id: CaseStep.Measures,
        label: getStepKey(CaseStep.Measures),
      })
    }

    return steps
  }, [caseData.status, isSecretRetainerType])

  const firstStep = useMemo(() => formSteps[0].id, [formSteps])

  const lastStep = useMemo(
    () => formSteps[formSteps.length - 1].id,
    [formSteps],
  )

  const canPublish = useMemo(
    () => caseData.status === CaseStatus.Creating,
    [caseData.status],
  )

  const isPublished = useMemo(
    () => caseData.status === CaseStatus.Requested,
    [caseData.status],
  )

  const submitRequest = async (formValues: CaseEditFormFields) => {
    const request: UpdateCaseRequest = mapCaseEditFormValuesForRequest(
      formValues,
      caseData.id,
    )

    await casesService.updateCase(request)
  }

  const handleNext = async () => {
    const isStepValid = await trigger()
    if (!isStepValid) {
      return
    }
    try {
      await submitRequest(getValues())

      setActiveStep(prevActiveStep => getNextStep(formSteps, prevActiveStep))
    } catch (error) {
      toastError(t('cases.errors.update'))
    }
  }

  const handleBack = async () => {
    if (activeStep === firstStep) {
      navigate(`/patients/${caseData.id}`)
    }

    setActiveStep(prevActiveStep => getPrevStep(formSteps, prevActiveStep))
  }

  const handleSaveAsDraft = async () => {
    clearErrors()

    try {
      await submitRequest(getValues())

      navigate('/patients')
      toastSuccess(t('cases.success.draft'))
    } catch (error) {
      toastError(t('cases.errors.update'))
    }
  }

  const handlePublishPatient: SubmitHandler<CaseEditFormFields> = async (
    formValues: CaseEditFormFields,
  ) => {
    const isStepValid = await trigger()
    if (!isStepValid) {
      return
    }
    try {
      await submitRequest({ ...formValues, published: true })
      navigate('/patients/')

      toastSuccess(t('cases.success.requested'))
    } catch (error) {
      toastError(t('cases.errors.requested'))
    }
  }

  return {
    handleNext,
    handlePublishPatient,
    handleBack,
    handleSaveAsDraft,
    allowEdit,
    formSteps,
    lastStep,
    canPublish,
    isPublished,
    isSecretRetainerType,
  }
}
