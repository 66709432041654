import { FC } from 'react'
import { Detail, ExternalLink, DetailItem } from '@app/components'
import { useTranslation } from 'react-i18next'
import { CiCircleCheck, CiVideoOn } from 'react-icons/ci'
import {
  PlanAcceptance,
  TreatmentPlan,
} from '@app/services/treatment-plans/types'
import Accordion from 'react-bootstrap/esm/Accordion'
import { TreatmentAmount } from '../treatment-amount/TreatmentAmount'

type TreatmentContentProps = {
  selectedPlan: TreatmentPlan
  withVisor: boolean
}
export const TreatmentContent: FC<TreatmentContentProps> = ({
  selectedPlan = null,
  withVisor,
}) => {
  const { t } = useTranslation()

  if (!selectedPlan) {
    return null
  }

  return (
    <Detail className="TreatmentContent">
      <Detail.Section className="row-gap-2">
        <Detail.Title className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
          {selectedPlan.name}

          {selectedPlan.acceptance === PlanAcceptance.Accepted && (
            <small className="text-primary">
              <CiCircleCheck className="fs-5" />{' '}
              {t('cases.acceptance.accepted')}
            </small>
          )}

          {withVisor && (
            <ExternalLink
              className="btn btn-primary btn-sm"
              href={selectedPlan.visorUrl!}
            >
              <CiVideoOn /> {t('cases.open-viewer')}
            </ExternalLink>
          )}
        </Detail.Title>

        <div className="col-12">
          <Accordion defaultActiveKey={['0']} flush alwaysOpen className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t('cases.treatment-data')}</Accordion.Header>
              <Accordion.Body>
                <Detail.Section>
                  <Detail.Section>
                    <div className="col-md-6">
                      <Detail.Section>
                        <Detail.Cell
                          label={t('cases.upper-aligners')}
                          value={selectedPlan.details.upperAligners}
                        />
                        <Detail.Cell
                          label={t('cases.lower-aligners')}
                          value={selectedPlan.details.lowerAligners}
                        />
                      </Detail.Section>
                    </div>

                    <div className="col-md-6 text-md-end">
                      <TreatmentAmount selectedPlan={selectedPlan} />
                    </div>
                  </Detail.Section>
                  <Detail.Cell
                    className="col-md-12 fw-normal"
                    label={t('cases.diagnostic')}
                    value={selectedPlan.details.diagnosis}
                  />
                  <Detail.Cell
                    className="col-md-12 fw-normal"
                    label={t('cases.treatment-plan')}
                    value={selectedPlan.details.treatmentPlan}
                  />
                </Detail.Section>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t('cases.movementTable')}</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-12">
                    <Detail.Cell
                      label={t('cases.upper-movement')}
                      value={selectedPlan.legacyPlan.movementTable.upper}
                    />
                  </div>
                  <div className="col-12">
                    <Detail.Cell
                      label={t('cases.lower-movement')}
                      value={selectedPlan.legacyPlan.movementTable.lower}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{t('cases.attaches')}</Accordion.Header>
              <Accordion.Body>
                {selectedPlan.legacyPlan.attaches
                  .sort((a, b) => a.number - b.number)
                  .map((item, index) => (
                    <DetailItem
                      key={index}
                      title={item.title}
                      description={item.description}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>{t('cases.stripping')}</Accordion.Header>
              <Accordion.Body>
                {selectedPlan.legacyPlan.stripping
                  .sort((a, b) => a.number - b.number)
                  .map((item, index) => (
                    <DetailItem
                      key={index}
                      title={item.title}
                      description={item.description}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>{t('cases.tads')}</Accordion.Header>
              <Accordion.Body>
                {selectedPlan.legacyPlan.tads
                  .sort((a, b) => a.number - b.number)
                  .map((item, index) => (
                    <DetailItem
                      key={index}
                      title={item.title}
                      description={item.description}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>{t('cases.recommendations')}</Accordion.Header>
              <Accordion.Body>
                <Detail.Section>
                  <Detail.Cell
                    className="col-md-12 fw-normal"
                    label={t('cases.recommendations')}
                    value={selectedPlan.details.recomendations}
                  />
                </Detail.Section>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Detail.Section>
    </Detail>
  )
}
