import { CiFloppyDisk } from 'react-icons/ci'
import { useTranslation } from 'react-i18next'
import { Case, isSecretRetainer } from '@app/services/cases/types'
import { FormProvider, useForm } from 'react-hook-form'
import { FC, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonAsync, Card } from '@app/components'
import { ClinicBase } from '@app/services/clinics/types'
import { BillingAddress } from '@app/services/billing-address/types'
import { coerceValues } from './utils'
import { buildValidationSchema } from './validation'
import { CaseStep, CaseEditFormFields } from '../../shared/form-steps/types'
import { Prescription } from '@app/services/prescription/types'

import {
  FilesForm,
  RadiographsForm,
  GeneralInfoForm,
  MeasuresForm,
  SpecificInfoForm,
} from '../../shared/form-steps'
import Button from 'react-bootstrap/esm/Button'
import { CaseBreadcrumb, CaseStepper } from '../../shared/form'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { AccessDenied } from '@app/pages/errors'
import { Form } from '@app/components/forms'
import { DEFAULT_EDIT_STEP, useCaseEditForm } from './useCaseEditForm'

interface CaseEditFormProps {
  caseData: Case
  clinics: ClinicBase[]
  billingAddress: BillingAddress[]
  problems?: Prescription[]
  solutions?: Prescription[]
}

export const CaseEditForm: FC<CaseEditFormProps> = ({
  clinics = [],
  billingAddress = [],
  caseData,
  problems,
  solutions,
}) => {
  const { t } = useTranslation()

  const [activeStep, setActiveStep] = useState(
    isSecretRetainer(caseData.type) ? CaseStep.General : DEFAULT_EDIT_STEP,
  )

  const methods = useForm<CaseEditFormFields>({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: coerceValues(caseData, problems!),
    resolver: yupResolver(buildValidationSchema(t, activeStep, caseData.type)),
  })
  const { handleSubmit } = methods

  const {
    handleNext,
    handlePublishPatient,
    handleBack,
    handleSaveAsDraft,
    allowEdit,
    formSteps,
    lastStep,
    canPublish,
    isPublished,
    isSecretRetainerType,
  } = useCaseEditForm({ caseData, methods, activeStep, setActiveStep })

  if (!allowEdit) {
    return <AccessDenied />
  }

  const getStepContent = (step: CaseStep) => {
    switch (step) {
      case CaseStep.General:
        return (
          <GeneralInfoForm clinics={clinics} billingAddress={billingAddress} />
        )
      case CaseStep.Specific:
        return (
          !isSecretRetainerType && (
            <SpecificInfoForm
              caseType={caseData.type}
              problems={problems!}
              solutions={solutions!}
            />
          )
        )
      case CaseStep.Files:
        return !isSecretRetainerType && <FilesForm />
      case CaseStep.Radiographs:
        return !isSecretRetainerType && <RadiographsForm />
      case CaseStep.Measures:
        return canPublish ? <MeasuresForm caseType={caseData.type} /> : null
      default:
        return 'Unknown step'
    }
  }

  return (
    <>
      <CaseBreadcrumb caseData={caseData} />

      <FormProvider {...methods}>
        <Form>
          <Card>
            <CaseStepper steps={formSteps} activeStep={activeStep} />

            {getStepContent(activeStep)}

            <Card.Footer className="align-items-center">
              <>
                <ButtonAsync
                  type={'button'}
                  className="btn btn-link"
                  onClick={handleSaveAsDraft}
                >
                  <CiFloppyDisk />
                  {isPublished ? t('common.save') : t('common.save-draft')}
                </ButtonAsync>

                <Button
                  type={'button'}
                  onClick={handleBack}
                  variant="secondary"
                >
                  <BsChevronLeft /> {t('common.back')}
                </Button>
                {activeStep === lastStep ? (
                  <ButtonAsync
                    type={'button'}
                    className="btn btn-primary"
                    onClick={async () => {
                      await handleSubmit(
                        async data => {
                          await handlePublishPatient(data)
                        },
                        errors => {
                          console.log('handleSubmit errors:', errors)
                        },
                      )()
                    }}
                  >
                    {canPublish ? t('common.request') : t('common.save')}
                  </ButtonAsync>
                ) : (
                  <ButtonAsync
                    className="btn btn-primary"
                    type={'button'}
                    onClick={handleNext}
                  >
                    {t('common.next')} <BsChevronRight />
                  </ButtonAsync>
                )}
              </>
            </Card.Footer>
          </Card>
        </Form>
      </FormProvider>
    </>
  )
}
