import { State } from '@app/core/types'
import { useCallback, useEffect, useState } from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import prescriptionService from '@app/services/prescription/prescription.service'
import { Prescription } from '@app/services/prescription/types'
import { CaseType } from '@app/services/cases/types'

type ProblemsState = State<Prescription[], 'problems'>
type SolutionsState = State<Prescription[], 'solutions'>

const initialProblemsState: ProblemsState = {
  loaded: false,
  problems: null,
}
const initialSolutionsState: SolutionsState = {
  loaded: false,
  solutions: null,
}

type UsePrescriptionType = {
  problemsState: ProblemsState
  solutionsState: SolutionsState
}

const cleanString = (str: string) => {
  return str.replace(/[\u200B-\u200D\u2060\u206D\uFEFF\u00A0]/g, '').trim()
}

export const usePrescription = (caseType?: CaseType): UsePrescriptionType => {
  const [problemsState, setProblemsState] =
    useState<ProblemsState>(initialProblemsState)
  const [solutionsState, setSolutionsState] = useState<SolutionsState>(
    initialSolutionsState,
  )

  const { toastError } = useToast()
  const { t } = useTranslation()

  const loadProblems = useCallback(async () => {
    setProblemsState(prevState => ({
      ...prevState,
      loaded: false,
    }))

    try {
      const { data: problems } = await prescriptionService.getProblems()

      const cleanedProblems = problems.map(problem => ({
        ...problem,
        name: cleanString(problem.name),
      }))

      setProblemsState(() => ({
        loaded: true,
        problems: cleanedProblems,
      }))
    } catch (error) {
      setProblemsState(prevState => ({
        ...prevState,
        loaded: true,
        problems: null,
        error: error as Error,
      }))
      toastError(t('errors.load-data'))
    }
  }, [t, toastError])

  const loadSolutions = useCallback(async () => {
    setSolutionsState(prevState => ({
      ...prevState,
      loaded: false,
    }))

    try {
      const { data: solutions } = await prescriptionService.getSolutions()

      const cleanedSolutions = solutions.map(solution => ({
        ...solution,
        name: cleanString(solution.name),
      }))

      setSolutionsState(() => ({
        loaded: true,
        solutions: cleanedSolutions,
      }))
    } catch (error) {
      setSolutionsState(prevState => ({
        ...prevState,
        loaded: true,
        solutions: null,
        error: error as Error,
      }))
      toastError(t('errors.load-data'))
    }
  }, [t, toastError])

  useEffect(() => {
    loadProblems()
    loadSolutions()
  }, [caseType, loadProblems, loadSolutions])

  return {
    problemsState,
    solutionsState,
  }
}
