import { FC, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonAsync, Card } from '@app/components'
import { Form } from '@app/components/forms'
import { useToast } from '@app/hooks'
import { ClinicBase } from '@app/services/clinics/types'
import { BillingAddress } from '@app/services/billing-address/types'
import { coerceValues } from './utils'
import { buildValidationSchema } from './validation'
import { CaseStep, CaseCreateFormFields } from '../../shared/form-steps/types'
import { CaseBreadcrumb, CaseStepper, FormStep } from '../../shared/form'
import { TreatmentTypeForm, GeneralInfoForm } from '../../shared/form-steps'
import { getStepKey } from '../../shared/form-steps/utils'
import { mapGeneralInfoValuesForRequest } from '../../shared/form-steps/2-general-info/utils'
import Button from 'react-bootstrap/esm/Button'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import casesService from '@app/services/cases/cases.service'
import treatmentTypesService from '@app/services/treatment-types/treatment-types.service'
import { useNavigate } from 'react-router-dom'
import { CaseTypeOption, isSecretRetainer } from '@app/services/cases/types'

interface CaseNewFormProps {
  clinics: ClinicBase[]
  billingAddress: BillingAddress[]
  disableAutoNext?: boolean
}

export const CaseNewForm: FC<CaseNewFormProps> = ({
  clinics = [],
  billingAddress = [],
  disableAutoNext = false,
}) => {
  const { t } = useTranslation()
  const { toastError } = useToast()
  const navigate = useNavigate()
  const firstErrorRef = useRef<HTMLElement | null>(null)

  const [activeStep, setActiveStep] = useState(CaseStep.Type)
  const [secretCases, setSecretCases] = useState<CaseTypeOption[]>([])

  const methods = useForm<CaseCreateFormFields>({
    mode: 'onChange',
    defaultValues: coerceValues(),
    resolver: yupResolver(buildValidationSchema(t, activeStep)),
  })

  const {
    watch,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = methods
  const typeWatch = watch('type')

  useEffect(() => {
    const fetchSecretCases = async () => {
      try {
        const data = await treatmentTypesService.getSecretCases()
        setSecretCases(data)
      } catch (error) {
        toastError(t('cases.errors.fetchSecretCases'))
      }
    }

    fetchSecretCases()
  }, [toastError, t])

  const getFormSteps = useMemo(() => {
    const steps: FormStep[] = [
      {
        id: CaseStep.General,
        label: getStepKey(CaseStep.General),
      },
    ]

    if (!isSecretRetainer(typeWatch)) {
      steps.push(
        {
          id: CaseStep.Specific,
          label: getStepKey(CaseStep.Specific),
        },
        {
          id: CaseStep.Files,
          label: getStepKey(CaseStep.Files),
        },
        {
          id: CaseStep.Radiographs,
          label: getStepKey(CaseStep.Radiographs),
        },
        {
          id: CaseStep.Measures,
          label: getStepKey(CaseStep.Measures),
        },
      )
    }
    return steps
  }, [typeWatch])

  const getStepContent = (step: CaseStep) => {
    switch (step) {
      case CaseStep.Type:
        return <TreatmentTypeForm secretCases={secretCases} />
      case CaseStep.General:
        return (
          <GeneralInfoForm clinics={clinics} billingAddress={billingAddress} />
        )

      default:
        return null
    }
  }

  const handleNext = useCallback(async () => {
    const isValid = await trigger('type')
    if (isValid) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }, [trigger])

  const handleBack = async () => {
    if (activeStep === CaseStep.Type) {
      navigate(`/patients`)
    }
    if (activeStep === CaseStep.General) {
      reset()
    }

    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSubmitForm: SubmitHandler<CaseCreateFormFields> = async (
    formValues: CaseCreateFormFields,
  ) => {
    const isStepValid = await trigger()
    if (!isStepValid) {
      const firstErrorField = Object.keys(errors)[0]
      if (firstErrorField) {
        const fieldElement = document.querySelector(
          `[name="${firstErrorField}"]`,
        )
        if (fieldElement) {
          firstErrorRef.current = fieldElement as HTMLElement
          firstErrorRef.current.scrollIntoView({ behavior: 'smooth' })
          firstErrorRef.current.focus()
        }
      }
      return
    }

    try {
      const request = mapGeneralInfoValuesForRequest(formValues)
      const id = await casesService.createCase(request)
      navigate(`/patients/${id}/edit`)
    } catch (error) {
      toastError(t('cases.errors.create'))
    }
  }

  useEffect(() => {
    if (!disableAutoNext && typeWatch) {
      handleNext()
    }
  }, [handleNext, typeWatch, disableAutoNext])

  return (
    <>
      <CaseBreadcrumb />
      <FormProvider {...methods}>
        <Form>
          <Card>
            {activeStep > 0 && (
              <CaseStepper activeStep={activeStep} steps={getFormSteps} />
            )}
            {getStepContent(activeStep)}
            <Card.Footer className="align-items-center">
              <>
                <Button
                  type={'button'}
                  onClick={handleBack}
                  variant="secondary"
                >
                  <BsChevronLeft /> {t('common.back')}
                </Button>
                {activeStep !== CaseStep.Type && (
                  <ButtonAsync
                    type={'button'}
                    className="btn btn-primary"
                    onClick={
                      activeStep === CaseStep.General
                        ? handleSubmit(handleSubmitForm)
                        : handleNext
                    }
                  >
                    {t('common.next')} <BsChevronRight />
                  </ButtonAsync>
                )}
              </>
            </Card.Footer>
          </Card>
        </Form>
      </FormProvider>
    </>
  )
}
